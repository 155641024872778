import { useFormContext } from 'react-hook-form'
import PublicMobileModal from 'components/PublicMobileModal'
import Headers from 'components/home/common/typography/Headers'
import { SearchModalFormProps } from 'components/modals/home/search/SearchModal'
import { DashboardSearchFormFields } from 'data/types/home/search'
import ControlledPropertyFilters from './ControlledPropertyFilters'

interface PropertyFiltersModalProps extends SearchModalFormProps {
  isOpen: boolean
  onClear?: () => void

  onFilter: () => void
}

export type PropertyFilterFields = {
  bedrooms?: number | null
  bathrooms?: number | null
  minimumPrice?: number | null
  maximumPrice?: number | null
  propertyTypes?: number[] | null
  searchCategories?: number[] | string[] | null
}

const PropertyFiltersModal = ({
  isOpen,
  onClose,
  onFilter,
}: PropertyFiltersModalProps) => {
  const { formState: { errors } } = useFormContext<DashboardSearchFormFields>()

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={() => {
        if (!errors?.filters) {
          onClose()
        }
      }}
    >
      <div className="flex items-center justify-end px-24 py-16">
        <Headers.H1 className="flex-1 text-center">
          Filters
        </Headers.H1>
      </div>

      <ControlledPropertyFilters
        onFilter={onFilter}
        button={{
          style: 'default',
        }}
      />
    </PublicMobileModal>
  )
}

export default PropertyFiltersModal
