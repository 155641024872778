import { Switch } from '@headlessui/react'
import { Noop } from 'react-hook-form'
import { KeyboardEvent, cloneElement, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { CheckIcon } from 'components/icons'

export type CheckboxOption = {
  value: string
  label: string
  component?: JSX.Element
}

interface CheckboxProps {
  disabled?: boolean
  value: string[]
  onChange: (...event: any[]) => void
  onBlur: Noop
  option: CheckboxOption
}

export default function Checkbox ({
  disabled,
  value = [],
  onChange,
  option,
  onBlur,
}: CheckboxProps) {
  const ref = useRef<HTMLButtonElement | null>(null)

  const checked = useMemo(() => value?.includes(option.value), [value, option.value])

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault()
      event.stopPropagation()
      if (ref.current?.previousElementSibling) {
        (ref.current?.previousElementSibling as HTMLElement).focus()
      }
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault()
      event.stopPropagation()
      if (ref.current?.nextElementSibling) {
        (ref.current?.nextElementSibling as HTMLElement).focus()
      }
    }
  }

  return (
    <>
      <Switch
        ref={ref}
        checked={checked}
        disabled={disabled}
        onChange={() => onChange(option.value)}
        onBlur={onBlur}
        className="py-2 cursor-pointer focus:outline-none group"
        onKeyDown={handleKeyDown}
      >
        <div className="flex items-center justify-between w-full gap-12">
          <p className={classNames('text-15', {
            'text-grey-800 font-bold': checked,
            'text-grey-750': !checked,
          })}>
            {option.label}
          </p>

          <div className="flex items-center justify-center w-24 h-24 rounded-full bg-grey-200 group-focus:ring-2 group-focus:ring-primary-100">
            {
              checked && (
                <div className="flex items-center justify-center w-24 h-24 rounded-full bg-primary">
                  <CheckIcon className="text-white text-24" />
                </div>
              )
            }
          </div>
        </div>
      </Switch>

      {
        (option.component && checked)
          && <div className="md:ml-20">
            {cloneElement(option.component, { isChild: true }) as JSX.Element}
          </div>
      }
    </>
  )
}
